

@font-face {
  font-family: 'Kollektif';
  src: url('fonts/kollektif-webfont.woff') format('woff'),
       url('fonts/kollektif-webfont.woff2') format('woff2')
}

@font-face {
  font-family: 'Kollektif-bold';
  font-weight: bold;
  src: url('fonts/kollektif-bold-webfont.woff') format('woff'),
       url('fonts/kollektif-bold-webfont.woff2') format('woff2')
}

@font-face {
  font-family: 'Kollektif-italic';
  font-style: italic;
  src: url('fonts/kollektif-italic-webfont.woff') format('woff'),
       url('fonts/kollektif-italic-webfont.woff2') format('woff2')
}

@font-face {
  font-family: 'Kollektif-italic-bold';
  font-weight: bold;
  font-style: italic;
  src: url('fonts/kollektif-bolditalic-webfont.woff') format('woff'),
       url('fonts/kollektif-bolditalic-webfont.woff2') format('woff2')
}

:root {
  /* green: rgb 26, 228, 114
  secondgreen: rgb(36, 213, 112) 
  thirdgreen: rgb(32, 193, 102) */
  /*--primarybrown: rgb(223, 174, 113);
  --brownsecondary: rgb(201, 152, 92);
  --browntertiary: rgb(175, 129, 72);*/
  --primarycolor: rgb(225, 108, 97);
  --buttonsecondary: rgb(205, 98, 70);
  --buttontertiary: rgb(185, 78, 50);
}

body {
  background-color: var(--primarycolor);
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
  height: auto;
}

.App {
  text-align: center;
  color: black;
  font-size: 6vw;
  font-family: 'Kollektif', sans-serif;
}

.App hr {
  border-color: var(--primarycolor);
  border-style: solid;
  border-width: .3vw;
  border-radius: 3vw;
  margin-top: -2vw;
  width: 20%;
}

.App-header {
  background-size:cover;
  padding-top: 10vw;
  padding-bottom: 5.2vw;
  color: rgb(255, 255, 255);
}

.App-header p {
  margin-bottom: 2vw;
}

.ContactButton {
  background-color: var(--primarycolor);
  width: 34vw;
  height: 12vw;
  font-family: 'Kollektif', sans-serif;
  font-size: 4.5vw;
  border: 0;
  border-radius: .6vw;
  color: black;
}

.ContactButton:hover{
  background-color: var(--buttonsecondary);
}

.ContactButton:active {
  background-color: var(--buttontertiary);
}

.PrimaryContent {
  background-color: #FFF;
  padding-bottom: 1vw;
  padding-top: 3.5vw;
}

.PrimaryContent h1 {
  margin-top: 0vw;
  margin-bottom:3.5vw;
  padding-bottom: 0vw;
  align-items: center;
  font-size: 5.75vw;
}

.AboutMe {
  padding-top: 3vw;
  box-shadow: 0 0 2vw var(--primarycolor);
  border-radius: 4vw;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

h1 {
  margin-bottom: 0vw;
}

.AboutMe p {
  font-size: 4vw;
  margin-left: 3vw;
  margin-right: 3vw;
  padding-bottom: 5vw;
  margin-top: 2vw;
  font-family: 'Kollektif', sans-serif;
}

.Logo {
  box-shadow: 0 0 1.25vw var(--primarycolor);
  padding: 3vw;
  width: 91px;
  height: 91px;
  border-radius: 20px;
  margin: -.7vw 1vw 3vw 1vw;
  align-content: center;
  display: inline-block;
}

.Logo img{
  width: 71px;
  height: 71px;
}

.Logo p {
  font-size: 20px;
  margin: 0 0 1.5vw 0;
}

.App-footer p {
  background-color: var(--primarycolor);
  font-size: 3vw;
}

.AboutMe img {
  width: 25%;
  height: auto;
  border-color: black;
  border-style: solid;
  border-color: rgb(235, 129, 120);
  border-radius: 3vw;
  border-width: .5vw;
  float:right;
  margin-left: 5vw;
  margin-right: 5vw;
}

.ProjectContainer {
  box-shadow: 0 0 1.25vw var(--primarycolor);
  padding: 3vw;
  padding-top: 4vw;
  padding-bottom: 3vw;
  border-radius: 20px;
  margin: -.7vw 5vw 4.5vw 5vw;
  align-content: center;
  display: inline-block;
}

.Projects wrapper :current {
  animation: slide-in-left 1s ease-in forwards;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.ProjectImage {
  width: 40%;
  height: auto;
  border-style: solid;
  margin-top: -11vw;
  border-radius: 2vw;
  border-width: .3vw;
  float: right;
}

li {
  margin-bottom: 2vw;
}

.Description {
  box-shadow: 0 0 0vw var(--primarycolor);
  margin-left: -3vw;
  width: 50vw;
  height: 35vw;
  border-radius: 2px;
  display: inline-block;
}

.LogoButton {
  margin: 0;
  padding: 3.5vw; 
  border-radius: 1vw;
  border-width: 0px;
  background-color: var(--primarycolor);
}

.LogoButton:hover {
  background-color: var(--buttonsecondary);
}

.LogoButton:active {
  background-color: var(--buttontertiary);
}